import React, { useEffect } from "react"

import BlogPostTemplate from "../Templates/blogPostTemplate"
import { ContentWrapper } from "../containers/alpacked.style"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import homeTemplate from "./index"
import { navigate } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"

const CaseStudy = ({ location }) => {
  useEffect(() => {
    const searchQuery = location.search

    // Define the allowed search queries
    const allowedSearchQueries = [
      "?utm_source=email&utm_medium=pdf_CICD_&_AI_Trends_CEO",
      "?utm_source=email&utm_medium=pdf_CICD_&_AI_Trends_CTO",
      "?utm_source=linkedin&utm_medium=pdf-CI/CD_&_AI-Trends_CEO",
      "?utm_source=linkedin&utm_medium=pdf-CI/CD_&_AI-Trends_CTO",
      "?utm_source=linkedin&utm_medium=pdf-CI/CD_&_AI-Trends_CEO-e",
      "?utm_source=linkedin&utm_medium=pdf-CI/CD_&_AI-Trends_CTO-e",
    ]

    // Check if the current search query is in the allowed search queries
    if (!allowedSearchQueries.includes(searchQuery)) {
      // If not, redirect to the default URL
      navigate(
        "/stay-updated-in-2024?utm_source=linkedin&utm_medium=pdf-CI/CD_&_AI-Trends_CTO-e"
      )
    }
  }, [location.search])

  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Stay Updated in 2024"
        description="CI/CD & AI Trends: Stay Updated in 2024"
      />
      <Layout>
        <ContentWrapper>
          <iframe
            src="/Stay_Updated_in_2024.pdf"
            width="100%"
            height="1000"
            title="CI/CD & AI Trends: Stay Updated in 2024"
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(CaseStudy, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})
